#hero
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: #999 url('../../assets/images/hero.webp') center center no-repeat
  background-blend-mode: multiply
  background-size: cover
  &.land
    display: flex
    justify-content: center
    align-items: center
    height: calc(100vh - 128px)
    background: none
