.title
  margin: 0
  padding: 0
  +v-xs
    font-size: 1.2em
  &-cta
    font-size: 4em
    font-weight: 200
    &-mini
      font-size: 2em
      font-weight: 200 !important
  &-intro
    font-size: 4.25em
    font-weight: 400
    &-section
      font-size: 4.35em
      font-weight: 600
    &-hc
      font-size: 4.35em
      font-weight: 700
    &-sub
      font-size: 1.75em
      font-weight: 200
      &-hc
        font-size: 1.4em
        font-weight: 400
        line-height: 1.5
  &-section
    font-size: 2em
    font-weight: 400
    line-height: 1.25
    letter-spacing: 3%
    text-transform: uppercase
    &-base
      font-size: 1em
      font-weight: 500
    &-mini
      text-transform: uppercase
    &-sub
      font-size: 1.35em
      font-weight: 300
      line-height: 1.4
      text-transform: none
  &-mini
    font-size: .75em
    font-weight: 300
    line-height: 1.4
    text-transform: uppercase
  &-name
    font-size: 1.1em
    font-weight: 400
    line-height: 1.4
  &-item
    font-size: 1.3em
    font-weight: 400
    line-height: 1.4
  &-creator
    font-size: 1.2em
    font-weight: 400
    &:hover
      color: $theme !important

.text
  &-card
    font-size: 1.35em
    font-weight: 400
    line-height: 1.25
