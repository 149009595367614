@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=auto')

$dark: #211D1D
$grey: #9e9e9e
$light: #F7F7F8
$pink: #FF2F93
$blue: #277CB9
$yellow: #F1BD00
$violet: #5F6095
$orange: #EB861C
$red: rgba(247,25,25,0.75)
$white: #fff
$logo: rgba(120, 141, 151, 0.75)
$start: #C5D290
$cherry: #2B1821

$body: $white
$button: $pink
$theme: $blue
$footer: $light

$basic: 'Outfit', sans-serif
$intro: 'Outfit', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
