// Colors
.c
  &-white
    color: $white !important
  &-dark
    color: $dark !important
  &-blue
    color: $blue !important
  &-pink
    color: $pink !important
  &-violet
    color: $violet !important
  &-light
    color: $light !important
  &-theme
    color: $theme !important
  &-grey
    color: $grey !important
  &-orange
    color: $orange !important
  &-yellow
    color: $yellow !important

.b-none
  border: none !important

// Background Colors
.bg
  &-body
    background-color: $body !important
  &-white
    background-color: $white !important
  &-dark
    background-color: $dark !important
  &-blue
    background-color: $blue !important
  &-light
    background-color: $light !important
  &-theme
    background-color: $theme !important
  &-grey
    background-color: $grey !important
  &-pink
    background-color: $pink !important
  &-orange
    background-color: $orange !important
  &-yellow
    background-color: $yellow !important
